export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js")
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/language": [4],
		"/monster": [5]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";